// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-germetizatsiya-derevyannogo-doma-js": () => import("./../../../src/pages/germetizatsiya-derevyannogo-doma.js" /* webpackChunkName: "component---src-pages-germetizatsiya-derevyannogo-doma-js" */),
  "component---src-pages-germetizatsiya-germetizatsiya-sruba-js": () => import("./../../../src/pages/germetizatsiya/germetizatsiya-sruba.js" /* webpackChunkName: "component---src-pages-germetizatsiya-germetizatsiya-sruba-js" */),
  "component---src-pages-germetizatsiya-konopatka-sruba-js": () => import("./../../../src/pages/germetizatsiya/konopatka-sruba.js" /* webpackChunkName: "component---src-pages-germetizatsiya-konopatka-sruba-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pokraska-doma-iz-brusa-js": () => import("./../../../src/pages/pokraska-doma-iz-brusa.js" /* webpackChunkName: "component---src-pages-pokraska-doma-iz-brusa-js" */),
  "component---src-pages-pokraska-sruba-js": () => import("./../../../src/pages/pokraska-sruba.js" /* webpackChunkName: "component---src-pages-pokraska-sruba-js" */),
  "component---src-pages-politika-konfidencialnosti-js": () => import("./../../../src/pages/politika-konfidencialnosti.js" /* webpackChunkName: "component---src-pages-politika-konfidencialnosti-js" */),
  "component---src-pages-porody-koz-alpine-poroda-koz-js": () => import("./../../../src/pages/porody-koz/alpine_poroda_koz.js" /* webpackChunkName: "component---src-pages-porody-koz-alpine-poroda-koz-js" */),
  "component---src-pages-porody-koz-burskaya-poroda-koz-js": () => import("./../../../src/pages/porody-koz/burskaya_poroda_koz.js" /* webpackChunkName: "component---src-pages-porody-koz-burskaya-poroda-koz-js" */),
  "component---src-pages-porody-koz-nubiskaya-poroda-koz-js": () => import("./../../../src/pages/porody-koz/nubiskaya_poroda_koz.js" /* webpackChunkName: "component---src-pages-porody-koz-nubiskaya-poroda-koz-js" */),
  "component---src-pages-porody-koz-toggenburgskaya-poroda-koz-js": () => import("./../../../src/pages/porody-koz/toggenburgskaya_poroda_koz.js" /* webpackChunkName: "component---src-pages-porody-koz-toggenburgskaya-poroda-koz-js" */),
  "component---src-pages-porody-koz-zaanenskaya-poroda-koz-js": () => import("./../../../src/pages/porody-koz/zaanenskaya_poroda_koz.js" /* webpackChunkName: "component---src-pages-porody-koz-zaanenskaya-poroda-koz-js" */),
  "component---src-pages-porody-ovets-dorper-poroda-ovec-js": () => import("./../../../src/pages/porody-ovets/dorper_poroda_ovec.js" /* webpackChunkName: "component---src-pages-porody-ovets-dorper-poroda-ovec-js" */),
  "component---src-pages-porody-ovets-frizskaya-poroda-ovec-js": () => import("./../../../src/pages/porody-ovets/frizskaya_poroda_ovec.js" /* webpackChunkName: "component---src-pages-porody-ovets-frizskaya-poroda-ovec-js" */),
  "component---src-pages-porody-ovets-texel-poroda-ovec-js": () => import("./../../../src/pages/porody-ovets/texel_poroda_ovec.js" /* webpackChunkName: "component---src-pages-porody-ovets-texel-poroda-ovec-js" */),
  "component---src-pages-prodazha-plemennyh-zhivotnyh-prodazha-koz-js": () => import("./../../../src/pages/prodazha-plemennyh-zhivotnyh/prodazha-koz.js" /* webpackChunkName: "component---src-pages-prodazha-plemennyh-zhivotnyh-prodazha-koz-js" */),
  "component---src-pages-prodazha-plemennyh-zhivotnyh-prodazha-ovets-js": () => import("./../../../src/pages/prodazha-plemennyh-zhivotnyh/prodazha-ovets.js" /* webpackChunkName: "component---src-pages-prodazha-plemennyh-zhivotnyh-prodazha-ovets-js" */),
  "component---src-pages-restavratsiya-derevyannyh-domov-js": () => import("./../../../src/pages/restavratsiya-derevyannyh-domov.js" /* webpackChunkName: "component---src-pages-restavratsiya-derevyannyh-domov-js" */),
  "component---src-pages-restavratsiya-derevyannyh-domov-restavratsiya-derevyannogo-pola-js": () => import("./../../../src/pages/restavratsiya-derevyannyh-domov/restavratsiya-derevyannogo-pola.js" /* webpackChunkName: "component---src-pages-restavratsiya-derevyannyh-domov-restavratsiya-derevyannogo-pola-js" */),
  "component---src-pages-restavratsiya-derevyannyh-domov-restavratsiya-fundamenta-js": () => import("./../../../src/pages/restavratsiya-derevyannyh-domov/restavratsiya-fundamenta.js" /* webpackChunkName: "component---src-pages-restavratsiya-derevyannyh-domov-restavratsiya-fundamenta-js" */),
  "component---src-pages-restavratsiya-derevyannyh-domov-restavratsiya-ventsov-sruba-js": () => import("./../../../src/pages/restavratsiya-derevyannyh-domov/restavratsiya-ventsov-sruba.js" /* webpackChunkName: "component---src-pages-restavratsiya-derevyannyh-domov-restavratsiya-ventsov-sruba-js" */),
  "component---src-pages-shlifovka-sruba-js": () => import("./../../../src/pages/shlifovka-sruba.js" /* webpackChunkName: "component---src-pages-shlifovka-sruba-js" */),
  "component---src-pages-solyanye-peschery-js": () => import("./../../../src/pages/solyanye-peschery.js" /* webpackChunkName: "component---src-pages-solyanye-peschery-js" */),
  "component---src-pages-vnutrennyaya-pokraska-doma-js": () => import("./../../../src/pages/vnutrennyaya-pokraska-doma.js" /* webpackChunkName: "component---src-pages-vnutrennyaya-pokraska-doma-js" */)
}

